import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/PageHeader'
import { createRichTextSerializer } from '../utils/prismic'

export const query = graphql`
  query {
    prismic {
      privacy_policy(uid: "privacy-policy", lang: "en-us") {
        title
        description
        text
      }
    }
  }
`

const bodySerializer = createRichTextSerializer({
  paragraphClassname: () => 'mb-4',
  ulClassname: () => 'mb-4',
})

const IndexPage = ({ data }) => {
  const { privacy_policy: privacyPolicy } = data.prismic
  return (
    <Layout>
      <SEO title={privacyPolicy.title} keywords={[`longevity`]} />

      <PageHeader
        titles={[privacyPolicy.title]}
        richDescription={privacyPolicy.description}
      />

      <div className="container lg:max-w-xl px-8 lg:px-0 mt-16 mb-24 lg:mb-32 mx-auto font-serif text-blue-dianne leading-tight font-normal">
        <article className="copy-2 measure break-words">
          {RichText.render(privacyPolicy.text, undefined, bodySerializer)}
        </article>
      </div>
    </Layout>
  )
}

export default IndexPage
